import {useNavigate} from 'react-router-dom';
import jobindexstatus from '../img/jix/jobindexstatus.png';
import jobindexcal from '../img/jix/jobindexcal.png';
import jobindexgif from '../img/jix/jobindexgif.gif';
import jixworkshop from '../img/jix/jixworkshop.jpg';
import jobindexbrowser from '../img/jix/jobindex-browser.png';
import jobindexsaved from '../img/jix/jobindex-saved.png';
import jobindexjourney from '../img/jix/jobindex-user-journey.png';
import React, {useState, useEffect} from 'react';

export default function Projects() {
    const navigate = useNavigate();
    const [projectHeroVisible,
        setProjectHeroVisible] = useState(true); // Assuming initially visible

    useEffect(() => {
        const handleScroll = () => {
            const projectHero = document.getElementById('project-hero');
            if (projectHero) {
                const projectHeroRect = projectHero.getBoundingClientRect();
                const isProjectHeroVisible = projectHeroRect.bottom > 0 && projectHeroRect.top < window.innerHeight;
                setProjectHeroVisible(isProjectHeroVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        <div className="home page">
                <button
                    className={`arrow ${ !projectHeroVisible
                    ? 'blue'
                    : ''}`}
                    onClick={() => navigate(-1)}>
                    <svg
                        width="120pt"
                        height="120pt"
                        version="1.1"
                        viewBox="0 0 1200 1200"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m434.2 607.72 103.31 89.258c4.7383 4.0977 11.902 3.5586 15.984-1.1719 4.0977-4.7383 3.5742-11.895-1.1641-15.992l-79.191-68.406h282.2c6.2578 0 11.336-5.0703 11.336-11.336s-5.0781-11.336-11.336-11.336h-285.05l45.141-40.289 35.262-27.887c4.9062-3.8828 5.7422-11.012 1.8594-15.922-2.2383-2.8281-5.5547-4.3008-8.9023-4.3008-2.4648 0-4.9414 0.79297-7.0273 2.4492l-35.77 28.324-66.789 59.582c-2.4336 2.168-3.8164 5.2812-3.793 8.5469 0.023437 3.2578 1.4453 6.3477 3.9258 8.4805z"/>
                    </svg>
                </button>
                <div className="top mb-6 bg-red ">
                <div className="container max-1200 block" id="project-hero">
                    <div className="jobindex-logo " alt="jobindex logo">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mb-8"viewBox="0 0 329 63.3"><path
                            fill="#fff"
                            d="M60.9 19.1c-17 0-20.3 4.4-20.3 21.9 0 17.1 3.6 21.8 20.3 21.8 16.9 0 20.4-4.2 20.4-21.8 0-18.1-3.3-21.9-20.4-21.9zm.3 33c-6.4 0-7.2-1.8-7.2-11.1 0-9.3.8-11.2 7.2-11.2 6.2 0 6.8 1.9 6.8 11.2 0 9.3-.6 11.1-6.8 11.1zM112.1 19.1c-4.9 0-9.2 1.1-11.3 6.6h-.6V1.2H86.9v61.1h13.3l-.3-7.4h.6c1.6 6.4 5.8 7.9 11.6 7.9 9.9 0 15.4-4.4 15.4-15.7v-9.8c.1-13-4.2-18.2-15.4-18.2zm-4.5 33c-7.1 0-7.4-2.9-7.4-11.2 0-9.7 1.9-11.1 7.4-11.1 6.2 0 6.7 3.9 6.7 11.1-.1 9-1.1 11.2-6.7 11.2zM179.6 19.1c-6.3 0-10.2 1.7-12.2 8.5h-.5v-8h-13.3v42.8h13.3V38.8c0-5.2 1-9 7-9 4.3 0 5.4 2 5.5 6.2v26.3h13.3V32.7c0-9.2-4.1-13.6-13.1-13.6zM226.3 25.7h-.6c-2.2-5.5-6.4-6.6-11.3-6.6-11.2 0-15.4 5.2-15.4 18.2v9.8c0 11.3 5.6 15.7 15.4 15.7 5.8 0 10.1-1.5 11.6-7.9h.6l-.3 7.4h13.3V1.2h-13.3v24.5zm-7.2 26.4c-5.6 0-6.8-.7-6.8-11.1 0-8.5.2-11.2 6.8-11.2 7.1 0 7.2 2.9 7.2 11.2 0 9.7-1.7 11.1-7.2 11.1zM265 19.1c-16.4 0-19.9 5.5-19.9 21.9 0 16 3.9 21.8 19.9 21.8 5.9 0 10.9-.5 14.4-2.5 3.5-2.1 5.5-5.8 5.5-12.2h-13.1c0 4.5-2.6 5-6.8 5-5.5 0-6.6-2.2-6.6-9.7h26.5V41c0-17.5-4.6-21.9-19.9-21.9zm-6.5 16.5c.2-5.6 1.6-6.8 6.4-6.8 4.5 0 6.7.4 6.7 6.8h-13.1zM317.2 39.7L328 19.5h-15.6l-4.9 11.9-4.6-11.9h-15.5l10.5 20.2-12.1 22.6h16.1l5.6-14.1 5.9 14.1H329zM133.2 19.5h13.3v42.8h-13.3zM139.7 13.7c3.8 0 6.9-3.1 6.9-6.9 0-3.8-3.1-6.9-6.9-6.9-3.8 0-6.9 3.1-6.9 6.9 0 3.8 3.1 6.9 6.9 6.9zM21.8 47.3c0 3.2-.5 4.3-4.7 4.3-3.7 0-4.1-1.5-4.1-5.7v-4.2H0v4.2C0 60 2.9 63.3 17 63.3c14.2 0 18.3-2.4 18.3-16.1V2H21.8v45.3z"/></svg>
                    </div>
                        <p className="case-study">Case Study | 2021</p>
                        <h1>Optimizing jobsearch</h1>
                        <section className="content mt-2">
                            <div className="item-top">
                                <p className="case-intro">Problems</p>
                                <ul>
                                <li><strong>Limited Platform Use</strong>: Users mainly utilize Jobindex for job searching and avoid logging in due to unclear benefits.</li>

  <li><strong>Low User Engagement</strong>: Users lacked tools to effectively manage job searches and applications, resulting in lower engagement and retention on the platform.</li>
  <li><strong>Disorganized Application Process</strong>: When people are looking for jobs, they often struggle to keep track of application statuses and deadlines, which can make the job search process feel overwhelming and disorganized.</li>
</ul>
                            </div>

                            <div className="item-top">
                            <p className="case-intro">Outcome</p>
                                
                                <ul>
    <li><strong>Enhanced User Engagement:</strong> Transformed Jobindex into a service that helps candidates manage applications, ultimately increasing user retention.</li>
    <li><strong>Streamlined Application Process:</strong> Introduced features like automatic application deadlines and status tracking, enabling candidates to stay organized and proactive in their job searches.</li>
</ul>
                                <p className="case-intro">My Role</p>
                                <div className="badges mb-1">
                            <span className="badge">Research</span>
                            <span className="badge">Product Strategy</span>
                            <span className="badge">UX/UI</span>
                            <span className="badge">Ideation</span>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
            <div className="container max-800 ">
                <h2>Introduction</h2>
                   <p className="mb-8">Jobindex is the largest job search platform and recruitment company in Denmark, connecting job seekers with employment opportunities and providing tools to manage their application process efficiently. However, through interviews, data, and research, we discovered that users often found the platform lacking in essential tools for managing their job searches. Many users, particularly the proactive user group preferred to track their applications offline using spreadsheets, as they sought greater control and clarity over their process, highlighting the need for more value and functionality within the platform.</p>
            </div>
            <div className="container max-1200 workshop">

                    <img src={jobindexjourney} className="img-fluid " alt="User Journey"/>
                    <p className="micro text-center ">Highlevel User Journey for a very active job seeker.</p>
            </div>

    





            <div className="container max-800 mb-8">

                <h2>One place to keeping track of all your interesting jobs</h2>
                <p>We wanted to transform Jobindex into a platform that facilitates the entire
                    job search process, rather than just a place where you find jobs and then move
                    on. Our goal was to evolve Jobindex from a mere job search engine into a
                    comprehensive tool that supports you through every step of the journey — helping
                    you manage applications, track progress, and engage with employers more
                    effectively.
                </p>

            </div>




            <div className="container max-1200 workshop">

                    <img src={jixworkshop} className="border-radius img-fluid mt-4 " alt="workshop"/>
<p className="xs text-center mb-4">                       Snapshot from one of our early sessions, mapping user pain points to uncover deeper insights.
</p>
            </div>
            <div className="container max-800 mb-8">

                <h2>Improving Scanability</h2>
                <p>Some users save many jobs, leading to a dense amount of visual information
                    that can be challenging to process. To address this, we propose removing the job
                    descriptions from the initial view and allowing users to access this information
                    only when opening the job card if needed. By eliminating these details and
                    graphics, and utilizing horizontal space more effectively, the saved jobs become
                    much easier to scan and manage.
                </p>

            </div>

            <div className="container max-1200">
                <div className="item ">
                    <img src={jobindexsaved} className="img-fluid pt-4" alt="The UI of saved jobs section in the old interface"/>
                    <p className="mini mb-2 mt-1">
                        <b>Before</b>
                        – the saved jobads contained a lot of information, and it was difficult to get a
                        bird’s eye view of all of you projects</p>
                </div>

                <div className="item mb-6">

                    <img src={jobindexbrowser} className="img-fluid pt-4" alt="The new UI of saved jobs section in the new interface"/>
                    <p className="mini mb-2 mt-1">
                        <b>After
                        </b>– removed a lot of information which generate less cognitive load and
                        therefore improve the scannability.</p>

                </div>
            </div>

          
            <div className="container max-800 mb-10">

                <div className="item  ">
                    <h2 className="mb-0">Introducing notes</h2>
                    <p>Many of the users write entries in a spreadsheet to keep track of where they
                        are in the process and to indicate any additional information.
                    </p>
                    <p >
                        <b>Solution </b>
                        – An expandable card where the user can write notes</p>
                    <img src={jobindexgif} className="img-fluid pt-4" alt="Animated UI of expanded note card"/>
                </div>
                <div className="item  ">
                    <h2 className="mb-0">Introducing application deadlines</h2>
                    <p>When you apply for a job, you want to know what the deadline is. However, the
                        deadline is not always present in the job ad, and just because the ad is offline
                        on Jobindex does not mean that it can no longer be applied for.</p>
                    <p >
                        <b>Solution </b>
                        – Jobindex automatically sets the application deadline whenever possible, or
                        enter the application deadline manually and</p>
                    <img src={jobindexcal} className="img-fluid pt-4 " alt="Automatic deadline visual"/>
                </div>

            </div>
            <div className="container max-800 mb-8">

                <h2>Choosing status</h2>

                <p>Just like with notes, many users also keep track of their job application
                    statuses in an Excel sheet. To accommodate this need, we suggest adding status
                    features directly on the job cards and allowing users to sort by these statuses.
                    This would help them manage their applications right on the platform, without
                    needing to rely on a separate spreadsheet.</p>
                <br/>
                <img src={jobindexstatus} className="img-fluid pt-4 mb-8 mt-4" alt="Choosing status UI"/>

            </div>

            <div className="container max-800 mb-8 block">

                <h2>Further work</h2>

                <p>Using notes is becoming a feature that more and more people are using on the
                    platform, so we will need to see how we can make further iterations on this to
                    make this feature more elegant and intuitive. The first step would be to invite
                    users in for interviews and find out how they interpret the notes feature.
                </p>

                <p>When it comes to choosing jobstatus there are too many different options to
                    choose from - so we need to iterate on this feature and most likely simplify it
                    quite a bit. Again here we will look at inviting some users in for interviews,
                    but also look at analytics to uncover what statuses are used and how it is used.
                    Then the interviews can uncover the why of things</p>

                <p>In addition, we plan to be able to pin and save jobs from other sites online.
                    However, this feature has not yet been developed</p>
                
                    <h2>Credits:</h2>
                <p>
                 
                    <strong>Team: </strong>
                    Gitte Tofte (Research and usability testing), Daniel Egebjerg (fullstack)<br/>
                 </p>
                 
                
            </div>

        </div>
    )
}