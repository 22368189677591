import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import overview from '../img/medable/overview.gif';
import oldtasks from '../img/medable/old-tasks.gif';
import newtasks from '../img/medable/new-tasks.gif';
import timeline from '../img/medable/timeline.png';
import visit from '../img/medable/visits1.gif';
import mobile from '../img/medable/mobilehand.jpg';
import mapping from '../img/medable/signup.png';
import appoverview from '../img/medable/appoverview.png';
import workshop from '../img/medable/workshop.jpg';
import appscreens from '../img/medable/appscreens.png';

export default function Projects() {
    const navigate = useNavigate();
    const [projectHeroVisible,
        setProjectHeroVisible] = useState(true); // Assuming initially visible

    useEffect(() => {
        const handleScroll = () => {
            const projectHero = document.getElementById('project-hero');
            if (projectHero) {
                const projectHeroRect = projectHero.getBoundingClientRect();
                const isProjectHeroVisible = projectHeroRect.bottom > 0 && projectHeroRect.top < window.innerHeight;
                setProjectHeroVisible(isProjectHeroVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="home page">
            <button
                className={`arrow ${ !projectHeroVisible
                ? 'blue'
                : ''}`}
                onClick={() => navigate(-1)}>
                <svg
                    width="120pt"
                    height="120pt"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m434.2 607.72 103.31 89.258c4.7383 4.0977 11.902 3.5586 15.984-1.1719 4.0977-4.7383 3.5742-11.895-1.1641-15.992l-79.191-68.406h282.2c6.2578 0 11.336-5.0703 11.336-11.336s-5.0781-11.336-11.336-11.336h-285.05l45.141-40.289 35.262-27.887c4.9062-3.8828 5.7422-11.012 1.8594-15.922-2.2383-2.8281-5.5547-4.3008-8.9023-4.3008-2.4648 0-4.9414 0.79297-7.0273 2.4492l-35.77 28.324-66.789 59.582c-2.4336 2.168-3.8164 5.2812-3.793 8.5469 0.023437 3.2578 1.4453 6.3477 3.9258 8.4805z"/>
                </svg>
            </button>
            <div className="top mb-6 bg-purple">
                <div className="container max-1200" id="project-hero">
                    <img
                        src={overview}
                        className="mb-4 img-fluid center border-radius"
                        alt="Medable apps"/>
                    <p className="case-study">Case Study | 2024</p>
                    <h1>Rebuilding Medable patient apps and Improving user engagement</h1>

                    <section className="content mt-2">
                        <div className="item-top">
                            <p className="case-intro">Problems</p>
                            <ul>
                                <li>
                                    <strong>Scalability Issues: </strong>
                                    The Medable patient apps are customized for each study, making scalability
                                    unfeasible and difficult to expand across multiple studies efficiently.</li>
                                <li>
                                    <strong>Information Overload: </strong>
                                    The homescreen presents all tasks for the next three months, causing significant
                                    cognitive load and confusion for patients.</li>
                                <li>
                                    <strong>Cold & Clinical Tone: </strong>
                                    Feedback from users indicates that the app feels impersonal, lacking the warmth
                                    and empathy necessary to connect with patients.</li>
                                <li>
                                    <strong>Accessibility Issues: </strong>
                                    Poor VoiceOver support, color contrast, missing alt text, and incorrect labeling
                                    create major accessibility barriers for disabled users.</li>
                            </ul>
                        </div>
                        <div className="item-top">
                            <p className="case-intro">Outcome</p>
                            <ul>
                                <li>Rebuilt Medable's iOS/Android patient apps, transitioning to a
                                    trial-agnostic architecture to support all clinical trials in one app</li>
                                <li>Developed a new sign-in/signup flow and engagement features to improve
                                    patient clarity on tasks, trial phase, and progress updates</li>
                                <li>Improved scalability, accessibility, and enhanced patient engagement in
                                    clinical trials</li>
                            </ul>
                            <p className="case-intro mt-3">My Role</p>
                            <div className="badges">
                                <span className="badge">Interviews</span>
                                <span className="badge">
                                    UX</span>
                                <span className="badge">UI</span>
                                <span className="badge">Product Design</span>
                                <span className="badge">Prototyping</span>
                                <span className="badge">App Architecture</span>
                            </div>
                        </div>

                    </section>
                </div>
            </div>

            <div className="container max-800 mb-8">
                <h2>Introduction
                </h2>
                <p>Medable is a tech company that improves clinical trials through remote
                    patient monitoring and data capture. I led the redesign of patient apps, from within a product triad, to boost
                    engagement and tackle key challenges. Previously, Medable had a
                    one-app-per-study approach, which created a growing maintenance burden — we
                    needed to develop a more scalable solution — one app for multiple studies. This
                    new approach aimed to streamline operations and improve the overall user
                    experience for patients.
                </p>
            </div>

            <div className="container max-800 mb-8">

                <h2>Initial Research
                </h2>

                <p>To understand user needs, our product manager and I conducted both
                    qualitative and quantitative research. We worked closely with our UX researchers
                    and held brainstorming sessions with a Patient Advocacy Panel, as well as
                    one-on-one interviews with site staff and former trial patients. This
                    helped us identify key pain points as mention above.</p>
                <p>The original apps lacked data tracking, leaving us reliant on qualitative
                    feedback and desk research. However the dev team made sure to implement an
                    analytics tool into the new app, so we were able to valuable insights into user
                    interactions going forward.

                </p>
                <p>
                    Together with the PM and dev team, I conducted an accessibility audit of the
                    current apps, revealing issues like insufficient color contrast and voice-over
                    support that didn't align with A11Y/WCAG standards. We worked closely to ensure
                    the new app met all accessibility requirements, improving usability not only for
                    patients with disabilities but for all users.</p>

            </div>

            <div className="container max-1200">
                <div className="item ">
                    <img
                        src={workshop}
                        className="img-fluid mt-2 50 border-radius"
                        alt="Animated gif that shows how the initial visit is shown to the user"/>
                    <p className="mini mt-1 ">
                        <strong>Collaborating with PMs and developers </strong>
                        to define the logic for revamping the apps to support multiple studies.
                    </p>
                </div>

                <div className="item ">

                    <img
                        src={mapping}
                        className="img-fluid mt-2 50 border-radius"
                        alt="Phone in the hands of a user who has the Medable app open"/>
                    <p className="mini  mt-1">
                        <strong>Mapping out new app architecture </strong>
                        for the sign up / sign in flow that allows for multiple studies in one app.
                    </p>
                </div>
            </div>

            <div className="container max-800 ">
                <h2>Avoid confusion in the sign-up process
                </h2>

                <p>To accommodate multiple studies, we introduced invite codes that fetch specific trials from the backend in the global environment. While these codes can be lengthy due to our backend setup, we streamlined access by incorporating a QR code scanning option, allowing users to quickly load the study in the app. This approach helps alleviate the difficulty of remembering the codes.</p>

                <p className="mb-6">To alleviate anxiety about making mistakes, we added a
                    screen that provides key information about the study. This aims to address
                    potential confusion, particularly for users who may be less tech-savvy and
                    really underline what the user is about to join.
                </p>

            </div>
            <div className="container max-1200">
                <div className="item w-100">
                    <img
                        src={appscreens}
                        className="img-fluid border-radius"
                        alt="Overview of the different app screens"/>
                    <p className="mini text-center">
                        Key screens in the new create user flow.
                    </p>
                </div>

            </div>
            <div className="container max-1200">
                <div className="item w-100 ">
                    <img
                        src={appoverview}
                        className="img-fluid border-radius"
                        alt="Overview of the different app screens"/>
                    <p className="mini mt-1 text-center">
                        The entire overview of signup / create user flow.
                    </p>
                </div>

            </div>

            <div className="container max-800 mb-8">

                <h2 className="mb-1">Enhancing patient Understanding and Guidance of trial phases
                </h2>
                <p>Clinical trials consist of multiple phases, and it’s essential that
                    patients understand what’s expected at each stage. However, the app lacked
                    any guidance on what to expect, leaving patients uncertain and reliant on
                    other touchpoints throughout the trial. To address this, it was to clearly
                    differentiate between these phases and provide patients with insight into
                    the journey ahead.
                </p>
            </div>

            <div className="container max-1200">
                <div className="item w-100">
                    <img
                        src={timeline}
                        className="img-fluid mt-4 border-radius"
                        alt="Trial phase overview"/>
                    <p className="mini mt-1 text-center">
                        A modal gives a brief phase overview, with detailed info available on the home
                        screen.
                    </p>
                </div>
            </div>
            <div className="container max-800 mb-10">

                <h2 className="mb-1">Keeping patients Informed About Upcoming Site Visits and Events
                </h2>
                <p>Upcoming site visits — such as the very first conversation with the Site or
                    getting blood work done, along with televisits, had not been presented to
                    patients, despite the information being available to us. This missed a
                    crucial opportunity to keep them engaged and informed. By highlighting upcoming
                    events, we address this gap and ensure patients stay both informed and
                    involved.

                </p>

            </div>

            <div className="container max-1200">
                <div className="item ">
                    <img
                        src={visit}
                        className="img-fluid mt-2 50 border-radius"
                        alt="Animated gif that shows how the initial visit is shown to the user"/>
                    <p className="mini mt-1 mb-6">
                        Dialogs appear when a new trial phase begins, providing more info to guide the
                        user on upcoming events. Illustrations are an important aspect of creating a
                        warmer tone.
                    </p>
                </div>

                <div className="item ">

                    <img
                        src={mobile}
                        className="img-fluid mt-2 50 border-radius"
                        alt="Phone in the hands of a user who has the Medable app open"/>
                    <p className="mini mt-1 mb-6">
                        The user is shown the time of day, duration, and a brief event summary, with the
                        option to read more on an external site.
                    </p>
                </div>
            </div>
            <div className="container max-800 mb-10">
                <h2 className="mb-1">Thoughts behind illustrations
                </h2>
                <p>We integrated illustrations to foster a warmer, more human connection with
                    users. However, we had to navigate between engaging design and the sponsors’
                    concerns about potential bias. Our aim was to create a supportive and
                    informative atmosphere that enhances understanding while maintaining the
                    neutrality essential in clinical trials.
                </p>
            </div>

            <div className="container max-800 mb-10">

                <h2 className="mb-1">Improving Trial overview by addressing Cognitive Load
                </h2>
                <p>The patient app served as a task manager, assisting clinical trial
                    patients with completing tasks such as consent forms, screenings,
                    questionnaires etc. These tasks are pre-defined in the trial protocol before the
                    trial begins. At the trial's start, the home screen displayed all tasks that
                    patients needed to complete over the next three months, which could impose a
                    significant cognitive load. Additionally, the home screen lacked information
                    about previous tasks, preventing patients from reviewing tasks they had
                    already completed.</p>
                <quote className="mt-4">"Honestly, being in this trial would make me so anxious.. There’s just so much to do.."</quote>
                <p className=" mb-4 mini ">— Woman in the Patient Advocacy Panel when shown some of the real cumbersome examples.</p>
                
                <p>
                    To ease cognitive load, we separated past and future tasks into subviews. The
                    active tasks remained on the home screen, while patients could still access
                    past/future tasks if needed.</p>
                <p>We also introduced task durations, as tasks could take anywhere from 1 minute
                    to over 30 minutes. The system would provide average completion times based on
                    the type of task — whether it was a questionnaire, a measurement, or another
                    activity. Over time, these estimates became more personalized, adapting through
                    AI models based on individual usage.</p>

            </div>

            <div className="container max-1200">
                <div className="item ">
                    <img
                        src={oldtasks}
                        className="img-fluid mt-4 50 border-radius"
                        alt="The way tasks was were structured before"/>
                    <p className="mini mt-1">
                        <strong>Before: </strong> Information Overload and Lack of Simplicity than can cause confusion and cognitive strain.
                    </p>
                </div>

                <div className="item ">

                    <img
                        src={newtasks}
                        className="img-fluid mt-4 50 border-radius"
                        alt="The new way tasks are structured in the app"/>
                    <p className="mini  mt-1">
                        <strong>After: </strong>
                        Past & future tasks split into subsections
                    </p>

                </div>
            </div>

            <div className="container max-800 mb-8">
                <h2>Conclusion & reflection
                </h2>

                <p>The overhaul of the Medable patient app significantly enhanced clinical trial
                    engagement within a challenging regulatory landscape. By adopting a
                    trial-agnostic architecture, we improved scalability, but fully engaging
                    patients remains a challenge.</p>

                <p>We focused on adding warmth and valuable information to the app, and feedback
                    from our patient panel indicated that the experience felt less confusing and had
                    a warmer tone. However, continuous feedback cycles after the app goes live is
                    needed for a clearer picture. Balancing compliance with user experience is
                    difficult, especially under the pressures from looming FDA regulations.</p>

                <p>The Danish office was shut down before all changes could be fully realized,
                    but another team within Medable picked up where we left off. For real change to
                    happen, we need to rethink not only the app’s design but also the foundational
                    setup of clinical trials. Introducing analytics tools was a step in the right
                    direction — helping us detect dropout rates and more — but the real challenge is
                    using that data to enhance trial design itself.</p>

                <p>Overall, this redesign represents progress. Maintaining a continuous feedback
                    loop and being responsive to user needs will be crucial for supporting patients
                    throughout their clinical trial journey.</p>
                    <h2>Credits:</h2>
                <p>

                    <strong>The Team: </strong>
                    Shoutout to the entire Danish Medable office, but especially to my product triad buddies, Adrian Rössler, and Kevin Pelgrims, as well as the site staff and Patient Advocacy Panel for their insights!        </p>    </div>
           
        </div>

    );
}