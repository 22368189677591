function blackbox() {
    return <div className="blackbox micro">    
    <div className="red-dot"></div><b>On paternity leave for now</b>, but back in business late 2024. <a href="mailto:perloekkegaard@gmail.com" className="temp-link">Get in touch</a> or connect on <a href="https://www.linkedin.com/in/per-lyhne-l%C3%B8kkegaard-1206151a/" className="temp-link">Linkedin</a>
    
    </div>;
  }
  


  export default blackbox;
  