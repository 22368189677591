import {useNavigate} from 'react-router-dom';
import omhulogo from '../img/omhu/omhuapp2.png';
import omhuloop from '../img/omhu/omhu-loop.png';
import postit from '../img/omhu/postit.png';
import ideas from '../img/omhu/ideas.png';
import capture from '../img/omhu/capture.gif';
import origami from '../img/omhu/origami.png';
import React, {useState, useEffect} from 'react';

export default function Projects() {
    const navigate = useNavigate();
    const [projectHeroVisible,
        setProjectHeroVisible] = useState(true); // Assuming initially visible

    useEffect(() => {
        const handleScroll = () => {
            const projectHero = document.getElementById('project-hero');
            if (projectHero) {
                const projectHeroRect = projectHero.getBoundingClientRect();
                const isProjectHeroVisible = projectHeroRect.bottom > 0 && projectHeroRect.top < window.innerHeight;
                setProjectHeroVisible(isProjectHeroVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="home page">
            <div className="top mb-6 bg-green">
            <div className="jobindex-logo " alt="jobindex logo">

                <img src={omhulogo} className="omhu-logo center img-fluid" alt="Omhu logo"/></div>
                <div className="row" id="project-hero">
                    <p className="case-study">Case Study | 2023</p>
                    <h1>Facilitating Workshop & Prototyping at Omhu</h1>

                    <section className="content mt-2">
                        <div className="item-top">
                            <p className="case-intro">Problems</p>
                            <ul>
                                <li>
                                    <strong>Traditional workshops can alienate introverts </strong>due to overwhelming group dynamics and excessive social interaction,
                                    limiting their engagement and contribution.</li>
                                <li>
                                    <strong>Lengthy ideation or sketching sessions can lead to fatigue </strong>
                                    and excessive effort on a single idea, stifling creativity and diminishing
                                    overall productivity.</li>
                                <li>
                                    <strong>Low-Quality Image Submission </strong>
                                    where The photos are too blurry to provide any useful information, making it
                                    impossible for dermatologists to make an accurate diagnosis.</li>
                            </ul>

                        </div>

                        <div className="item-top">
                            <p className="case-intro">Outcome</p>
                            <ul>
                                <li>
                                    <strong>Inclusive Workshop Facilitation: </strong>
                                    Led a dynamic workshop that ensured equal participation from introverts and
                                    extroverts, turning user insights into a functional prototype.</li>
                                <li>
                                    <strong>Rapid ideation </strong>
                                    encourages quick brainstorming and sketching, allowing participants to generate
                                    a diverse range of ideas without getting bogged down in one concept.</li>
                                <li>
                                    <strong>Prototype with in-app guidance </strong>
                                    that provides real-time feedback on photo quality, to help users capture clear images before submission.</li>
                            </ul>

                            <p className="case-intro mt-3">My Role</p>
                            <div className="badges mb-1">
                                <span className="badge">Workshop facilitation</span>
                                <span className="badge">Prototyping</span>
                                <span className="badge">Tests</span>

                                <span className="badge">Ideation</span>
                                <span className="badge">Sketching</span>

                            </div>

                        </div>
                    </section>
                </div>
            </div>
            <button
                className={`arrow ${ !projectHeroVisible
                ? 'blue'
                : ''}`}
                onClick={() => navigate(-1)}>
                <svg
                    width="120pt"
                    height="120pt"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m434.2 607.72 103.31 89.258c4.7383 4.0977 11.902 3.5586 15.984-1.1719 4.0977-4.7383 3.5742-11.895-1.1641-15.992l-79.191-68.406h282.2c6.2578 0 11.336-5.0703 11.336-11.336s-5.0781-11.336-11.336-11.336h-285.05l45.141-40.289 35.262-27.887c4.9062-3.8828 5.7422-11.012 1.8594-15.922-2.2383-2.8281-5.5547-4.3008-8.9023-4.3008-2.4648 0-4.9414 0.79297-7.0273 2.4492l-35.77 28.324-66.789 59.582c-2.4336 2.168-3.8164 5.2812-3.793 8.5469 0.023437 3.2578 1.4453 6.3477 3.9258 8.4805z"/>
                </svg>
            </button>
            <div className="container max-800 mb-10">
                <h2>Introduction</h2>
                <p>
                Omhu Care was a  teledermatology app where users could receive an assessment of their skin symptoms by our in-house dermatologists. Data from our app showed that dermatologists often had to decline diagnosing a high percentage of patients due to insufficient image quality. We had conducted interviews with both dermatologists and our app users, and it became evident that users struggled to capture quality photos.</p>
            </div>
            <div className="container max-1200 workshop">
                <img src={omhuloop} className="img-fluid mt-4 mb-4" alt="Omhu loop"/>
            </div>
            <div className="container max-800 workshop">

                <h2>The Workshop</h2>
                <p>The workshop aimed to quickly go from initial user insights to a
                    single, actionable prototype. We started with a divergent mindset, mapping out problems and generating a
                    wide range of ideas. Then narrowed our focus to a few strong concepts through a
                    convergent process. This approach made it possible to take one key idea and
                    transform it from an initial concept to a functional prototype in just a few
                    days. .</p>
                <p>Think of it like a creative bootcamp where the finish line isn’t a polished,
                    scalable product but rather a prototype that’s ready to be tested and refined.
                    It’s about taking insights from users and quickly turning them into a tangible
                    form, iterating until we have something real and ready for the next step.</p>

                <h2>Phase 1: Insights & Problem Framing
                </h2>
                <p>Before the workshop, participants were briefed on initial research from
                    interviews and data points. This established a shared understanding of user
                    needs and challenges, ensuring our discussions were based on real insights
                    rather than the group’s preconceptions. Our goal was to explore the reasons
                    behind users submitting poor-quality images, allowing us to identify specific
                    problems to solve. While we would have preferred real users over colleagues, we
                    embraced the opportunity to make progress. We synthesized
                    research findings into clear, actionable "How Might We" statements so we had a 
                    shared understanding as a base for the ideation sessions.

                </p>
                <h3 class="omhu-h3">Activities</h3>
                <ul>
                    <li>Each participant independently wrote down pressing issues for 5 minutes to
                        gather unbiased input. This approach ensured that introverts and extroverts could contribute
                        equally without being influenced by dominant voices in the group.</li>
                    <li>All individual issues were posted on a whiteboard.</li>
                    <li>The team collectively discussed these issues and used affinity mapping to
                        categorize and prioritize them.</li>
                    <li>This process highlighted the most critical pain points from both
                        dermatologists' and patients' perspectives.</li>
                </ul>

                <h3 class="omhu-h3">Problem statements</h3>
                <ul>
                        <li>
                            <strong>Lack of Awareness: </strong>
                            Users may be unaware of the importance of image quality for effective
                            communication, resulting in the submission of blurry images without recognizing
                            the impact.</li>
                        <li>
                            <strong>Insufficient Guidance: </strong>
                            Users may not receive adequate instructions on techniques for taking sharp
                            images, leading to confusion about focus, framing, and stabilization.</li>
                        <li>
                            <strong>Technical Limitations: </strong>
                            Users might be using low-quality cameras or devices that lack stabilization
                            features, resulting in blurry images due to movement or inadequate resolution.</li>
                        <li>
                            <strong>Time Constraints: </strong>
                            Users may rush the image-capturing process due to tight deadlines or
                            distractions, leading to hurried shots that are often blurry.</li>
                        <li>
                            <strong>Environmental Constraints: </strong>
                            Users may encounter unfavorable physical or social environments, such as crowded
                            spaces or poor lighting, which hinder their ability to capture clear images.</li>
                    </ul>
            </div>
            <div className="container max-1200 workshop">

                <img src={postit} className="img-fluid py-4" alt="Post-it notes"/>
            </div>
            <div className="container max-800 workshop border-radius mb-6">
                <div class="mb-4 bg-white border-radius p-4">

                    <h3 class="omhu-h3">Reframing the problems into design opportunites</h3>
                   

                    <p class="xs mb-3">Transforming the most agreed-upon pain points into actionable "How Might We"
                        statements effectively drove our ideation and prototyping phases. This approach
                        encouraged creative thinking and collaboration, allowing us to explore solutions
                        for raising awareness about image quality and providing clearer guidance on
                        techniques for sharp images. Overall, this method clarified our objectives and
                        inspired innovative ideas aligned with real user needs.</p>
                        <h3 class="omhu-h3">How Might We Statements</h3>

                    <ul>
                        <li>How might we raise users' awareness of the importance of image quality for
                            effective communication?</li>
                        <li>How might we provide clearer guidance on techniques for taking sharp images?</li>
                        <li>How might we address the technical limitations of users' devices to help
                            them capture clearer images?</li>
                        <li>How might we encourage users to take their time when capturing images, even
                            under distractions?</li>
                        <li>How might we design solutions that help users navigate unfavorable
                            environments to capture clear images?</li>
                    </ul>
                </div>
            </div>
            <div className="container max-800 mb-4">
                <h2>Phase 2: Rapid Ideation
                </h2>
                <p>In this phase, the team brainstormed possible solutions to the problem.
                    Unlike broader ideation sessions, we remained very focused on generating ideas
                    directly tied to the problem statement which kept kept us aligned.

                </p>
                <p>The articipants sketched low fidelity concepts that solved one or
                    more of the problems we had come up with. Each concept conveyed a specific
                    moment in the interaction - before, during or after the image capture.</p>
                <p>The team was given 5 minutes to make the sketch, and subsequently hand over
                    the sketch to another one in the group. This should help participants not get
                    tunnel vision about their own ideas and instead keep their minds open about
                    different directions. We wanted to facilitate a space that continuously ignited
                    new inspiration within the group members and freed them from already established
                    ideas and assumptions.</p>
                <p className="mb-6">The sketching session left us with about 30 different design
                    concepts, which we afterward grouped and boiled down to 10 concepts that seemed
                    feasible and valuable. We then mapped these concepts into an impact-effort
                    matrix, helping us prioritize which ideas offered the most value relative to
                    their implementation complexity.</p>

                <img src={ideas} className="img-fluid py-4 mb-4 w-50" alt="Ideas"/>
            </div>
            <div className="container max-800 mb-10 pt-5">
                <h2 className="mb-0">Phase 3: Iterative Prototyping
                </h2>
                <p>We selected a few sketches as the foundation for the interactive prototype and iterated on them to refine the user flow. This prototype explored whether we could guide the user to hold the phone steady by introducing a small square that moves whenever the user shifts the phone.</p>

            </div>
            <div className="container max-1200 pt-5">

                <div className="capture mb-3">
                    <img src={capture} className="omhu-vid mb-3" alt="Capture prototype"/>
                </div>
            </div>

            <div className="container max-800 mb-10 pt-5">

                <div class="mb-3">
                    <h3 class="omhu-h3">Choosing the right prototyping tool</h3>

                    <p>I needed to have access to the accelerometer in the mobile phone and be able
                        to take and save a picture, which is not possible using Figma, Protopie, etc.
                        Therefore, the choice fell on Origami, as this would give me these
                        possibilities.</p>
                    <p>This software is completely different from other prototyping software like
                        Principle or Protopie as it uses visual programming where you "write" your code
                        using boxes and lines.</p>
                </div>
            </div>
            <div className="container max-1200 pt-5">

                <img src={origami} className="img-fluid mb-5" alt="Origami prototype"/>
            </div>
            <div className="container max-800 ">

                <div class="mb-4 bg-white p-4 border-radius">
                    <h3 class="omhu-h3">Phase 4: Testing & Iteration
                    </h3>

                    <p>
                        We conducted one round of user testing towards the end of the workshop, testing
                        the prototype with four individuals who were not involved in the project to
                        ensure unbiased feedback. This test provided crucial insights into how the
                        prototype functioned in a real-world context. Users misunderstood a key feature
                        — specifically, they thought the square indicated focus rather than movement. This feedback highlighted areas that needed further
                        refinement to better align the prototype with user expectations.</p>
                </div>
            </div>
            <div className="container max-800 ">
                <h2>Conclusion and reflections</h2>
                <p>By the end of the workshop, we had developed a functional prototype. However,
                    it was clear that the prototype was just a starting point rather than a final
                    solution. The feedback we received during testing highlighted issues we could reiterate on. </p>
                <p>
                    While this rapid approach had its benefits — producing something
                    tangible in a short period — it left us with more unresolved questions than
                    answers and the next steps would require much more detailed refinement.</p>
                <p >In the end, this experience underlined that rapid iteration
                    is valuable, but it should be balanced with time for more thoughtful
                    exploration. The single-prototype approach helped us dive deep, multiple iterations would
                    have resulted in a more refined prototype.</p>
                <h2>Credits:</h2>
                <p>

                    <strong>Workshop team: </strong>
                    Alice Moynihan, Tørk Egebjerg, Malena Jespersen, Mads Heinrichsen, Johan
                    Sundgren, Matt Avis<br/>
                </p>

            </div>
        </div>
    );
}
