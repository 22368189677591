import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import hero from '../img/hero.png';
import work from '../img/menu_work.png';
import mojn from '../img/menu_me.png';
import gif from '../img/per.jpg';
import omhu from '../img/omhu.png';
import jobindex from '../img/jobindex-2.png';
import Popup from '../components/popup'; // Import the Popup component
import appoverview from '../img/medable/overview.gif';

export default function Home() {
    const [showPopup,
        setShowPopup] = useState(false);
    const [isAuthenticated,
        setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const handleLinkClick = (e) => {
        if (!isAuthenticated) {
            e.preventDefault();
            setShowPopup(true);
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handlePasswordSubmit = (password) => {
        if (password === '1Q84') {
            setIsAuthenticated(true);
            sessionStorage.setItem('isAuthenticated', 'true');
            setShowPopup(false);
            navigate('/Medable'); // Redirect to /Medable
        } else {
            alert('Incorrect password');
        }
    };

    useEffect(() => {
        const authStatus = sessionStorage.getItem('isAuthenticated');
        if (authStatus === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    return (
        <div className="home page">
            {showPopup && (<Popup onClose={handleClosePopup} onPasswordSubmit={handlePasswordSubmit}/>)}
            <section className="red-bg">
                <div className="middle mt-4">
                    <h1 class="mega">Experienced Product Designer, UX/UI & Strategic Innovation</h1>
                   
                    <img src={gif} className="img-fluid" alt="gif"/>
                    <div className="services mt-3">
                        <div className="item-25 services">
                            <p className="case-intro">disciplines</p>
                            <ul className="small">

                            <li>UX Research</li>
                            <li>Product Strategy</li>
                            <li>Service Design</li>
                            <li>Workshop facilitation</li>
                            <li>Ideation & Prototyping</li>
                            <li>Interaction Design (UX/UI)</li>

                            </ul>
                        </div>
                        <div className="item-25 contact">
                            <p className="case-intro">Currently at</p>
                            <ul className="small">
                                <li className="paternity">Paternity Leave 🐣</li>
                                <li >
                                    <span className="strike">Medable
                                    </span>
                                    <span className="pill ml-1">Health Tech</span>
                                </li>
                                <li >
                                    <span className="strike">Omhu
                                    </span>
                                    <span className="pill ml-1 ">Health Tech</span>
                                </li>
                                <li >
                                    <span className="strike">Jobindex
                                    </span>
                                    <span className="pill ml-1">Job Portal</span>
                                </li>
                                <li >
                                    <span className="strike">GoMore
                                    </span>
                                    <span className="pill ml-1">P2P Ridesharing</span>
                                </li>
                            </ul>
                        </div>
                        <div className="item-50 blob">
                            <p className="case-intro">About</p>
                            <p className="small">Product Design Lead from Copenhagen, Denmark, currently on
                                paternity leave. Prior to this, I was involved in building a platform for
                                decentralized clinical trials at Medable, a Silicon Valley-based company
                                renowned as a leading technology provider for patient-centric clinical trials. I
                                span across various disciplines, including Design Direction, Product Design,
                                Digital Experience, Strategy, and other related fields.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <hr id="work" className='mt-5'/>

            <div className="container-projects">
                <div className="container-projects-item_left-20 mt-4">
                    <div className='sticky '>
                        <img src={work} className="mini" alt=""/>
                        <div className='left-stick ml-2'>
                            <div className="menu-title">Work</div>
                            <p>Selected casestudies
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container-projects-item_right-80">
                    <div class="">
                        <Link to="/Medable" className='cursor' onClick={handleLinkClick}>
                            <div className='cases'>
                                <div className="case-title">
                                    <h3>End-to-end design of mobile apps</h3>
                                    <p>MEDABLE</p>

                                </div>
                                <div className="container-projects-item_right-80">
                                    <img src={appoverview} className="img-fluid" alt=""/>
                                    <div className="pill-container"><span className="   pill">Research</span><span className="  pill">Ideation</span><span className="   pill">UI</span><span className="pill  ">UX</span><span className="  pill">App architecture</span><span className="pill">End-to-end Design</span>
                                    </div>
                                    <p className='xs mb-2'>
                                        Medable's software is used by leading companies like <strong>Pfizer, Novo Nordisk, and Eli Lilly </strong>
                                        — Pfizers COVID-19 vaccine trials was conducted on this platform. In this case
                                        study, I’ll discuss our app redesign and how we enhanced patient engagement by
                                        providing more accessible information, transforming the way patients interact with clinical trials.</p>
                                    <span className='black-arrow'>
                                        See Case Study
                                        <button type="submit" class="mb-1">
                                            <svg
                                                width="24"
                                                height="16"
                                                viewBox="0 0 24 16"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9L1 7ZM23.7071 8.70711C24.0976 8.31658 24.0976 7.68342 23.7071 7.29289L17.3431 0.928932C16.9526 0.538408 16.3195 0.538408 15.9289 0.928932C15.5384 1.31946 15.5384 1.95262 15.9289 2.34315L21.5858 8L15.9289 13.6569C15.5384 14.0474 15.5384 14.6805 15.9289 15.0711C16.3195 15.4616 16.9526 15.4616 17.3431 15.0711L23.7071 8.70711ZM1 9L23 9V7L1 7L1 9Z"
                                                    fill="#1D1D1B"></path>
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to="/Omhu">
                            <div className='cases'>
                                <div className="case-title">
                                    <h3>Workshop Facilitation</h3>
                                    <p>OMHU</p>
                                </div>
                                <div className="container-projects-item_right-80">
                                    <img src={omhu} className="img-fluid" alt=""/>
                                    <div className="pill-container"><span className="   pill">Workshop facilitation</span><span className="pill">Ideation</span><span className="  pill">Sketching</span><span className="pill">prototyping</span>
                                    </div>
                                    <p className='xs mb-2'>

                                        The case study highlights a workshop at Omhu that addressed challenges in user
                                        engagement and image quality submission by fostering inclusive participation,
                                        implementing rapid ideation, and developing a functional prototype, ultimately
                                        revealing critical design flaws and emphasizing the need for balanced iteration
                                        in the design process.
                                    </p>
                                    <span className='black-arrow'>
                                        See Case Study
                                        <button type="submit" class="mb-1">
                                            <svg
                                                width="24"
                                                height="16"
                                                viewBox="0 0 24 16"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9L1 7ZM23.7071 8.70711C24.0976 8.31658 24.0976 7.68342 23.7071 7.29289L17.3431 0.928932C16.9526 0.538408 16.3195 0.538408 15.9289 0.928932C15.5384 1.31946 15.5384 1.95262 15.9289 2.34315L21.5858 8L15.9289 13.6569C15.5384 14.0474 15.5384 14.6805 15.9289 15.0711C16.3195 15.4616 16.9526 15.4616 17.3431 15.0711L23.7071 8.70711ZM1 9L23 9V7L1 7L1 9Z"
                                                    fill="#1D1D1B"></path>
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to="/Jobindex">
                            <div className='cases'>
                                <div className="case-title">
                                    <h3>Investigating new product offerings</h3>
                                    <p>JOBINDEX</p>
                                </div>
                                <div className="container-projects-item_right-80 mb-200">
                                    <img src={jobindex} className="img-fluid" alt=""/>
                                    <div className="pill-container"><span className="   pill">User journey</span><span className="  pill">Ideation</span><span className="pill">Research</span><span className="pill">UX/UI</span>
                                    </div>
                                    <p className='xs mb-2'>
                                        
The case study highlights key issues with Jobindex, including limited platform use due to unclear benefits, inadequate application management tools, unclear user value, and cognitive overload from overwhelming information in saved job listings.</p>
                                    <span className='black-arrow'>
                                        See Case Study
                                        <button type="submit" class="mb-1">
                                            <svg
                                                width="24"
                                                height="16"
                                                viewBox="0 0 24 16"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9L1 7ZM23.7071 8.70711C24.0976 8.31658 24.0976 7.68342 23.7071 7.29289L17.3431 0.928932C16.9526 0.538408 16.3195 0.538408 15.9289 0.928932C15.5384 1.31946 15.5384 1.95262 15.9289 2.34315L21.5858 8L15.9289 13.6569C15.5384 14.0474 15.5384 14.6805 15.9289 15.0711C16.3195 15.4616 16.9526 15.4616 17.3431 15.0711L23.7071 8.70711ZM1 9L23 9V7L1 7L1 9Z"
                                                    fill="#1D1D1B"></path>
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <hr id="mojn"/>

            <div className="container-projects mt-4">
                <div className="container-projects-item_left-20 mt-4">
                    <div className='sticky '>
                        <img src={mojn} className="mini " alt=""/>
                        <div className='left-stick ml-2'>
                            <div className="menu-title">About</div>
                            <p>Experience<br />Education<br />Background</p>
                    {/* 
                     
                            <p className='xs'>
                                <b>[ˈmʌjn]</b>
                            </p>
                            <p className="xs ">Southern Jutlandish for Hello... or Goodbye, whatever you choose.</p>
                       */} </div> 
                    </div>
                </div>
                <div className="container-projects-item_right-60 about">
                    {/* <div className='per mb-3 ' >
            <img src={per} className="img-fluid" alt=""/>
            <p className='xs m-2'><b>My bird Müsli and I</b> hanging out.. keeping it real you know..</p>
          </div>*/}

                    <h2 className="h2-responsive">Experience</h2>
                    <div className="about-1 ">
                        <div className='item-45 mb-6'>
                            <h3>Sr. Product Designer | Medable</h3>
                            <p className="subcaption mb-2">2022 - 2024</p>
                            <p>Medable is transforming the clinical trial landscape and making it more accessible through digital solutions. By digitizing key touchpoints in the patient journey, it enables remote participation, making clinical trials available to a much broader audience and creating a smoother, more efficient experience for everyone involved.</p>
                            <ul>
    <li>Design lead for patient experience team building iOS, Android, and web applications used by patients in clinical trials, enhancing engagement and streamlining complex journeys.</li>
    <li>Co-led the Danish team within a product triad, transforming research into effective outcomes for patient-facing platforms, including final UI design and micro-interactions.</li>
    <li>Redesigned the mobile apps to encompass multiple studies in a unified experience, breaking free from the old one-app-per-study model and thereby enhancing scalability.</li>
    <li>Conducted user research including interviews, user journey mapping, UX audits etc.</li>
</ul>

                        </div>
                        <div className='item-45 mb-6'>
                            <h3>Sr. UX Designer | Omhu</h3>
                            <p className="subcaption mb-2">2021 - 2022</p>
                            <p>Omhu is an innovative dermatology platform, empowering patients in their
                                healthcare journey. Born from Leo Pharma's innovation lab, it offers remote dermatological consultations, significantly reducing traditional healthcare wait times by delivering expert skin consultations within 48 hours.</p>
                                <ul>
  <li>Designed web-based diagnostic tool, enabling dermatologists to remotely diagnose patients and enhance interactions for both doctors and patients.</li>
  <li>Co-led design efforts for patient-facing iOS and Android solutions, empowering users to connect with dermatologists for remote diagnoses and enhancing communication between patients and doctors.</li>
  <li>Facilitated cross-functional workshops, sketching, and brainstorming sessions.</li>
</ul>

                        </div>
                        <div className='item-45 mb-6'>
                            <h3>UX Specialist | Jobindex</h3>
                            <p className="subcaption mb-2">2018 - 2021</p>
                            <ul>
                                <li>Led the complete redesign of the B2B site, significantly improving user
                                    experience and driving higher engagement and conversion rates.</li>
                                <li>Implemented and managed a comprehensive design system, enhancing consistency
                                    across all platforms.</li>
                                <li>Set up new processes and digitised key services, such as the company
                                    profile, saving digital designers 3-4 working days per profile sold, all while
                                    maintaining high-quality standards.</li>
                                <li>Conceptualising, prototyping, designing and coding web applications using
                                    tools such as Sketch & Invision, Adobe creative suite, Html, scss, js, vue.js
                                </li>
                            </ul>
                        </div>
                        <div className='item-45 mb-6'>
                            <h3>Digital Designer | GoMore</h3>
                            <p className="subcaption mb-2">2015 - 2017</p>
                            <ul>
                                <li>Creating comprehensive brand photo guidelines and training GoMore's other
                                    markets on their implementation (Sweden, Norway, France, and Spain).</li>
                                <li>Concept development and executing online and offline campaigns including
                                    graphic design, photo, and video.</li>
                                <li>Building an asset library with images and videos, etc., for marketing use.</li>
                            </ul>
                        </div>
                    </div>
                    <h2 className="h2-responsive">Education</h2>
                    <div className="about-1">
                        <div className='item-45'>
                            <h3>IT–University of Copenhagen
                            </h3>
                            <p className="subcaption mb-2">2015 - 2017</p>
                            <p className="small margin-0">Cand IT, Digital Design & Communication</p>
                        </div>
                        <div className='item-45'>
                            <h3>Copenhagen school of design and technology
                            </h3>
                            <p className="subcaption mb-2">2013-2015</p>
                            <p className="small margin-0">AP graduate</p>
                        </div>
                        <div className='item-45'>
                            <h3>Rhythmic Music Conservatory
                            </h3>
                            <p className="subcaption mb-2">2005 - 2008</p>
                            <p className="small margin-0">Bachelor in Music & Arts</p>
                        </div>
                    </div>
                    <h2 className="h2-responsive">Background</h2>
                    <div className="about-1">
                        <div className='item-45'>
                            <p className="about-1 mt-2">
                                As a design lead, my role revolves around unveiling hidden pain points and
                                uncovering fresh market opportunities for companies. With hands-on expertise in
                                strategy, coding, and prototyping, alongside a stash of behavioral science books
                                discreetly tucked under my bed, I bring a unique skill set to the table for
                                building and promptly validating innovative concepts.
                                <br/><br/>
                                My journey began as a professionally trained musician and composer, shaping my
                                approach to creativity and innovation. This background lends a distinctive
                                perspective to my work, blending artistic creativity seamlessly with
                                technological innovation. I Believe we should embrace the unpredictability of
                                the creative process and acknowledge that the fundamental journey is inherently
                                unpredictable and something we should celebrate. However, to break down complex
                                problems into manageable parts, a toolbox is essential, and Design Thinking
                                frameworks are often an effective way to guide this process.
                                <br/><br/>
                                I seek roles where I can build strong product visions and tackle real problems,
                                transforming innovative ideas into bold concepts, whether as a leader or through
                                hands-on design.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-projects-item_left-30 ">
                <div className="white-box mt-5 minus">
                    <img src={hero} className="cursor logo" alt=""/>
                    <h2 class="h2-responsive">Product Design Lead</h2>
                    <h3>perloekkegaard@gmail.com</h3>
                </div>
            </div>

        </div>
    );
}
