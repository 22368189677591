import React, { useState } from 'react';

const Popup = ({ onClose, onPasswordSubmit }) => {
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onPasswordSubmit(password);
    };

    return (
        <div className="popup-overlay">
            <div className="popup">
                <div className="popup-inner ">
                <h2 class="uppercase mb-1 mt-4">Topsecret stuff!</h2>
                <div className=" mb-2">
                To dive into this case study, you’ll need a password directly from yours truly.</div>
                <form onSubmit={handleSubmit}>
                    <input 
                        placeholder='Enter Password'
                        type="password" 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required 
                    />
                    <button type="submit" className="mb-1"><svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9L1 7ZM23.7071 8.70711C24.0976 8.31658 24.0976 7.68342 23.7071 7.29289L17.3431 0.928932C16.9526 0.538408 16.3195 0.538408 15.9289 0.928932C15.5384 1.31946 15.5384 1.95262 15.9289 2.34315L21.5858 8L15.9289 13.6569C15.5384 14.0474 15.5384 14.6805 15.9289 15.0711C16.3195 15.4616 16.9526 15.4616 17.3431 15.0711L23.7071 8.70711ZM1 9L23 9V7L1 7L1 9Z" fill="#F1F1E8"/>
</svg>
</button>

                </form>
                <button className="secondary-button mb-3" onClick={onClose}><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.3963 16.3474C0.83541 15.7962 0.854752 14.8194 1.37696 14.2972L6.90852 8.76567L1.37696 3.25346C0.854752 2.72157 0.83541 1.75452 1.3963 1.19363C1.95719 0.623064 2.93392 0.642405 3.45613 1.17429L8.97802 6.69617L14.4999 1.17429C15.0415 0.632734 15.9892 0.632734 16.5501 1.19363C17.1206 1.75452 17.1206 2.70223 16.5694 3.25346L11.0572 8.76567L16.5694 14.2876C17.1206 14.8388 17.111 15.7768 16.5501 16.3474C15.9988 16.9083 15.0415 16.9083 14.4999 16.3667L8.97802 10.8448L3.45613 16.3667C2.93392 16.8986 1.96687 16.9083 1.3963 16.3474Z" fill="black"/>
</svg>

</button>

            </div>
            </div>
        </div>
    );
};

export default Popup;