import {useNavigate} from 'react-router-dom';
import {Link} from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
};

export default function Projects() {

  const navigate = useNavigate();

    return (
      
<div className="home">     
<button className="arrow" onClick={() => navigate(-1)}>
              <svg width="120pt" height="120pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
              <path d="m434.2 607.72 103.31 89.258c4.7383 4.0977 11.902 3.5586 15.984-1.1719 4.0977-4.7383 3.5742-11.895-1.1641-15.992l-79.191-68.406h282.2c6.2578 0 11.336-5.0703 11.336-11.336s-5.0781-11.336-11.336-11.336h-285.05l45.141-40.289 35.262-27.887c4.9062-3.8828 5.7422-11.012 1.8594-15.922-2.2383-2.8281-5.5547-4.3008-8.9023-4.3008-2.4648 0-4.9414 0.79297-7.0273 2.4492l-35.77 28.324-66.789 59.582c-2.4336 2.168-3.8164 5.2812-3.793 8.5469 0.023437 3.2578 1.4453 6.3477 3.9258 8.4805z"/>
              </svg>
 </button>
    <div className="container"><h1>Resume</h1>
      </div>
  <div className="container mb-10">
    <div className="item  ">
      <h2 className="mb-0">Sr. Product Designer | Medable</h2>
      <p className="mini mb-2 mt-1">2022 - Current (Medable acquired Omhu early 2022) </p>
      <p>At Medable, we are reinventing clinical trials to be more inclusive and human-centered. We remove common barriers such as geography, economic status and ethnicity by introducing decentralized clinical trials that allow people to participate remotely using connected devices and smartphones as essential tools.

I'm Lead Product Designer, with a focus on the participant-facing platforms.</p>
      <a href="https://www.medable.com/">www.Medable.com</a>
    </div>
    <div className="item  ">
      <h2 className="mb-0">Sr. UX Designer | Omhu  </h2>
      <p className="mini mb-2 mt-1">2021 - 2022</p>
      <p>At Omhu (Leo Innovation lab spinout), we built an AI-infused dermatology experience. Omhu was born from the idea that the current skin health system is broken. In Denmark alone, the waiting time for a dermatologist is ~ 3 months, and in some countries it is not even possible to get an appointment. My responsibility was end-to-end design of the product, including discovery, facilitation of design sprints etc.
      </p>
      <Link to="/Omhu" onClick={scrollToTop()}> <span>See Casestudy </span><span className="arrow2">&#10230;</span></Link>
    </div>
    <div className="item  ">
      <h2 className="mb-0">UX Specialist | Jobindex </h2>
      <p className="mini mb-2 mt-1">2018 - 2021</p>
      <p>While being at Jobindex from 2018-2021 my focus was partly creating and implementing a Design system that covered Jobindex, It-Jobbank and Stepstone.dk, but also conceptualizing new services for for the massive platform.
      </p>
      <Link to="/Jobindex" onClick={scrollToTop()}> <span>See Casestudy </span><span className="arrow2">&#10230;</span></Link>
    </div>
    <div className="item  ">
      <h2 className="mb-0">Digital Designer | GoMore</h2>
      <p className="mini mb-2 mt-1">2015 - 2017</p>
      <p>GoMore was the first successful peer-to-peer ridesharing platform created out of Denmark. I was part of the team from 2015-2017, where I developed and built the brand together with a bunch of highly skilled people. My responsibility spanned across concepts, creative campaigns, landingpages, asset libraries and more.  </p>
      <a href="https://www.gomore.dk/">www.GoMore.dk</a>
    </div>

  </div>
  <hr className="mb-10 mt-8" />
  <div className="container"><h2>Education</h2>
      </div>
  <div className="container mb-8">
    <div className="item ">
      <h3 className="half-line mb-0">Cand IT, Digital Design & Communication</h3>
      <p className="mt-0 half-line mb-0">2015 – 2017<br />
     IT University of Copenhagen</p>
     <a href="https://www.itu.dk/">www.itu.dk</a>
    </div>
    <div className="item ">
      <h3 className="half-line mb-0">AP Graduate in Multimedia Design</h3>
     <p className="mt-0 half-line mb-0"> 2013 – 2015<br />
      KEA - Københavns Erhvervsakademi</p>
      <a href="https://www.kea.dk/">www.kea.dk</a>

    </div>
    <div className="item ">
      <h3 className="half-line mb-0">Bachelor in Music & Arts</h3>
     <p className="mt-0 half-line mb-0">2005 – 2008<br />
      Rytmisk Musikkonservatorium</p>
      <a href="https://www.rmc.dk/">www.rmc.dk</a>

    </div>
   
  </div>
  <hr className="mb-10" />

</div>
    )
  }

