import './App.scss';
import './margins.css';
import './fonts.scss';
import './_colors.scss';
import './_blackbox.scss';
import './menu.scss';

import bottom from './img/bottom.png';

import Home from './pages/Home.js';
import Medable from './pages/Medable.js';
import Projects from './pages/Projects.js';
import Omhu from './pages/Omhu.js';
import Jobindex from './pages/Jobindex.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sapp from './components/navmenu';
import Blackbox from './components/blackbox';
import ScrollToTop from './components/ScrollToTop';
import ScrollToAnchor from './components/scrollToAnchor';



function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Sapp />
        <ScrollToAnchor />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Projects" element={<Projects />} />
          <Route path="Omhu" element={<Omhu />} />
          <Route path="Jobindex" element={<Jobindex />} />
          <Route path="Medable" element={<Medable />} />
        </Routes>
        <Blackbox />  
      </Router>
      <img src={bottom} className="img-fluid bottom-img" alt=""/>

    </div>
  );
}

export default App;
